export enum ShowTimingType {
    /**
     * Show for the duration of the state effect
     */
    ALL_TIME = 0,
    /**
     * @deprecated
     */
    OWNER_HANDLING,
}

export enum EffectOutputType {
    /**
     * Attach to the Bip01 bone (or the root bone? Bip01 happens to be the root of most)
     */
    ATTACH = 0,
    
    /**
     * Unknown/unused
     */
    DO_ACTION,
    
    /**
     * Attach to a FX dummy bone
     */
    DUMMY_BONE_ATTACH,
}

/**
 * Doesn't appear to be supported
 */
export enum EffectPlayType {
    REPEAT = 0,
    ONCE,
    PART_REPEAT,
}
