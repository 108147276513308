























































































































import { filters } from '@/filters/Filters';
import ISkillTreeEntry from '@/models/skills/ISkillTreeEntry'
import { ISkillFullData, ISkillSimState, ISkillTree, ISp } from '@/views/SkillSimStore';
import Vue from 'vue'
import { SkillType } from '@/models/skills/SkillEnums';
import { renderUiString } from '@/util/Utils';
import { WeaponType, WeaponTypeName } from '@/models/items/ItemEnums';
import { SkillLevelTableRow } from '@/api/SkillProvider';

export default Vue.extend({
    props: {
        skillTreeEntry: {
            type: Object as () => ISkillTreeEntry,
        },
        index: {
            type: Number as () => number,
        },
        x: {
            type: Number as () => number,
        },
        y: {
            type: Number as () => number,
        }
    },
    computed: {
        skillSimState(): ISkillSimState {
            return (this.$store.state as any).skillsim;
        },
        embedded(): boolean {
            return this.skillSimState.embedded;
        },
        skills(): Map<number, ISkillFullData> {
            return this.skillSimState.skills;
        },
        skill(): ISkillFullData {
            return this.skills.get(this.getAwakeningMapping ? this.getAwakeningMapping : this.skillTreeEntry.skillId)!;
        },
        getAwakeningMapping(): number {
            return this.skillSimState.awakeningMods[this.skillTreeEntry.skillId] || 0;
        },
        currentLevelData(): SkillLevelTableRow|null {
            if (this.level > 0) {
                return this.skill.levels.find((v) => v._SkillLevel === this.level && v._ApplyType === 0) || null;
            }
            
            return null;
        },
        nextLevelData(): SkillLevelTableRow|null {
            return this.skill.levels.find((v) => v._SkillLevel === this.level + 1 && v._ApplyType === 0) || null;
        },
        maxRegularLevel(): number {
            return this.skill.skill._MaxLevel - this.skill.skill._SPMaxLevel;
        },
        maxLevel(): number {
            return this.skill.skill._MaxLevel;
        },
        hasTechLevels(): boolean {
            return this.maxRegularLevel !== this.maxLevel;
        },
        level(): number {
            return this.skillSimState.skillBuild[this.index];
        },
        isTech(): boolean {
            return false;
        },
        availableLevels(): number[] {
            return this.skillSimState.maxAvailableLevel;
        },
        availableLevel(): number {
            return this.availableLevels[this.index];
        },
        entryClass(): any {
            const ret: any = [];
            
            ret.push(filters.skillType(this.skill.skill._SkillType));

            if ((this.embedded ? (!this.hasCurrentLevel && this.hasMoreLevels) : this.hasMoreLevels) && this.hasCurrentLevel) {
                ret.push('wide');
            }

            return ret;
        },
        hasCurrentLevel(): boolean {
            return this.level > 0;
        },
        hasMoreLevels(): boolean {
            return this.level < this.maxLevel;
        },
        SkillTypeEnum(): any {
            return SkillType;
        },
        skillType(): SkillType {
            return this.skill.skill._SkillType;
        },
        element(): number {
            return this.skill.skill._Element;
        },
        learnLevel(): number {
            if (this.currentLevelData) {
                return this.currentLevelData._LevelLimit;
            }
            
            return 0;
        },
        nextLearnLevel(): number {
            if (this.nextLevelData) {
                return this.nextLevelData._LevelLimit;
            }
            
            return 0;
        },
        desc(): string {
            if (this.currentLevelData) {
                if (this.currentLevelData._SkillExplanationID_txt) {
                    const ret = renderUiString(this.currentLevelData._SkillExplanationID_txt);
                    return ret;
                }
                
                return `[MID:${this.currentLevelData._SkillExplanationID}]`;
            }
            
            return '';
        },
        nextDesc(): string {
            if (this.nextLevelData) {
                if (this.nextLevelData._SkillExplanationID_txt) {
                    const ret = renderUiString(this.nextLevelData._SkillExplanationID_txt);
                    return ret;
                }
                
                return `[MID:${this.nextLevelData._SkillExplanationID}]`;
            }
            
            return '';
        },
        nextSp(): number {
            if (this.nextLevelData) {
                return this.nextLevelData._NeedSkillPoint;
            }
            
            return 0;
        },
        requiredWeapons(): string {
            const weap1 = this.skill.skill._NeedWeaponType1;
            const weap2 = this.skill.skill._NeedWeaponType2;
            const weaps = [];
            if (weap1 >= 0) {
                weaps.push((WeaponTypeName as any)[weap1]);
            }
            
            if (weap2 >= 0) {
                weaps.push((WeaponTypeName as any)[weap2]);
            }
            
            return weaps.join(', ');
        },
        isNextLevelTech(): boolean {
            return this.level + 1 > this.maxRegularLevel;
        },
        skillBuild(): number[] {
            return this.skillSimState.skillBuild;
        },
        skillTree(): ISkillTree {
            return this.skillSimState.skillTree;
        },
        maxSp(): ISp {
            return this.skillSimState.maxSp;
        },
        unfulfilledPrereqs(): any[] {
            const skillSlot = this.skillTreeEntry;
            const ret = [];
            
            const sp: ISp = {
                first: this.computeTreeUsage(this.skillTree.first, 0),
                second: this.computeTreeUsage(this.skillTree.second, 24),
                third: this.computeTreeUsage(this.skillTree.third, 48),
                total: 0,
            };
            
            sp.total = sp.first + sp.second + sp.third;
            
            if (skillSlot.spRequired.first > sp.first) {
                ret.push(`${skillSlot.spRequired.first} SP in first tree`);
            }
            if (skillSlot.spRequired.second > sp.second) {
                ret.push(`${skillSlot.spRequired.second} SP in second tree`);
            }
            if (skillSlot.spRequired.third > sp.third) {
                ret.push(`${skillSlot.spRequired.third} SP in third tree`);
            }
            
            const remainingSp: ISp = {
                first: this.maxSp.first - sp.first,
                second: this.maxSp.second - sp.second,
                third: this.maxSp.third - sp.third,
                total: this.maxSp.total - sp.total,
            };
            
            if (this.nextLevelData && remainingSp.total < this.nextLevelData._NeedSkillPoint) {
                ret.push(`Need ${this.nextLevelData._NeedSkillPoint} SP free`);
            }
            
            const tree = this.indexToTree(this.index);
            const remainingTreeSp = remainingSp[tree];
            if (this.nextLevelData && remainingTreeSp < this.nextLevelData._NeedSkillPoint) {
                ret.push(`Need ${this.nextLevelData._NeedSkillPoint} SP free in tree`);
            }
            
            // Do we have the prerequisite skills
            if (skillSlot.parentSkills.length) {
                let failed = false;
                for (const parentSkill of skillSlot.parentSkills) {
                    const slot = this.skillTree.all.find((v) => v.skillId === parentSkill.id);
                    if (slot) {
                        const parentLevel = this.skillBuild[slot.index + (slot.tab - 1) * 24 + (slot.isAwakened ? 24 : 0)];
                        const parentSkillInfo = this.skills.get(parentSkill.id);
                        const parentSkillName = (parentSkillInfo && parentSkillInfo.skill._NameID_txt) || `Skill ${parentSkill.id}`;
                        if (parentLevel < parentSkill.level) {
                            ret.push(`Learn Lv.${parentSkill.level} [${parentSkillName}]`)
                        }
                    }
                }
            }
            
            return ret;
        },
    },
    methods: {
        computeTreeUsage(tree: ISkillTreeEntry[], offset: number) {
            let sum = 0;

            for (const entry of tree) {
                const skill = this.skills.get(entry.skillId);
                if (skill) {
                    const index = entry.index + offset + (entry.isAwakened ? 24 : 0);
                    const level = this.skillBuild[index];
                    
                    const maxRegularLevel = skill.skill._MaxLevel - skill.skill._SPMaxLevel;
                    sum += skill.levels.filter((v) => v._SkillLevel <= level && v._ApplyType === 0 && v._SkillLevel <= maxRegularLevel).reduce((pv, v) => pv + v._NeedSkillPoint, 0);
                }
            }

            return sum;
        },
        indexToTree(idx: number): 'first'|'second'|'third' {
            const mod = Math.floor(idx / 24);
            switch (mod) {
                case 0: return 'first';
                case 1: return 'second';
                case 2: return 'third';
                default: return 'third';
            }
        },
    },
})
